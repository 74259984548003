import "react-app-polyfill/ie9"; // For IE 9-11 support
import "react-app-polyfill/stable";
// import 'react-app-polyfill/ie11'; // For IE 11 support
import "./polyfill";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import Login from "./views/Login";
import * as serviceWorker from "./serviceWorker";
import axios from "axios";

axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

ReactDOM.render(<App />, document.getElementById("root"));

// export function goToDashboard() {
//   return ReactDOM.render(<App />, document.getElementById("root"));
// }

// export function goToLogin() {
//   return ReactDOM.render(<Login />, document.getElementById("root"));
// }

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
