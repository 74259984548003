import React, { Component } from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter,
  Router,
  Route,
  Switch,
  Link,
  Redirect
} from "react-router-dom";
import axios from "axios";
import App from "../../App";
import { goToDashboard, goToLogin } from "./../../index";
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row
} from "reactstrap";
import { API_SERVER } from "../../utils/values";
import GoogleMapReact from "google-map-react";

var sessionstorage = require("sessionstorage");

class Login extends Component {
  constructor(props) {
    super(props);

    if(sessionstorage.getItem("token")){
      this.state = {
        token: sessionstorage.getItem("token"),
        redirect: "dashboard"
      }
    }else{
      this.state = {
        token: null,
        login: 0,
        redirect: "login",
        usernameLogin: "",
        passwordLogin: ""
      };
    }

  }
  componentDidMount() {
    console.log(this.state.token);
    console.log(this.state.redirect);
  }

  login(username, password) {
    // console.log(username, password);
    // this.setState({ toDashboard: true });
    axios
      .post(API_SERVER + "/admin/login", {
        username: username,
        password: password
      })
      .then(res => {
        if (res.status === 200) {
          console.log(res.data);
          sessionstorage.setItem("token", res.data.token);
          sessionstorage.setItem("admin_username", res.data.username);
          sessionstorage.setItem("admin_fullname", res.data.fullname);
          // console.log(sessionstorage.getItem("token"));
          this.setState({ token: res.data.token, redirect: "dashboard" });
          console.log(this.state.token);
        }
        if (res.status === 400) {
          alert("Wrong Credentials");
        }
      });

    // if (response.data.success === true) {
    //   if (response.data.data.username === "admin") {
    //     goToDashboard();
    //   } else {
    //     alert("Wrong Username or Password");
    //   }
    // } else return alert(response.data.success);
    // });
  }

  handleApiLoaded(map, maps) {
    console.log("WOIII UDAH NGELOAD KOK!?!?");
  }

  render() {
    if (this.state.redirect === "dashboard") {
      return (
        <Redirect
          to={{
            pathname: "./dashboard",
            state: { token: this.state.token }
          }}
        />
      );
    }
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <Form
                      onSubmit={e => {
                        e.preventDefault();
                        this.login(
                          this.state.usernameLogin,
                          this.state.passwordLogin
                        );
                      }}
                    >
                      <h1>Login</h1>
                      <p className="text-muted">Sign In to your account</p>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-user"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="text"
                          placeholder="Username"
                          autoComplete="username"
                          value={this.state.usernameLogin}
                          onChange={e =>
                            this.setState({ usernameLogin: e.target.value })
                          }
                        />
                      </InputGroup>
                      <InputGroup className="mb-4">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-lock"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="password"
                          placeholder="Password"
                          autoComplete="current-password"
                          value={this.state.passwordLogin}
                          onChange={e =>
                            this.setState({ passwordLogin: e.target.value })
                          }
                        />
                      </InputGroup>
                      <Row>
                        <Col xs="6">
                          <Button
                            color="primary"
                            className="px-4"
                            onClick={() =>
                              this.login(
                                this.state.usernameLogin,
                                this.state.passwordLogin,
                                this.state.toDashboard
                              )
                            }
                          >
                            Login
                          </Button>
                        </Col>
                        <Col xs="6" className="text-right">
                          <Button color="link" className="px-0"></Button>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Login;
